<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="wrap">


        <router-link class="goBack" to="/">
          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z"
                  fill="#0033A0"/>
          </svg>
          назад
        </router-link>

        <h1>
          <span>Задача: работа с новым заявителем</span>
        </h1>


        <div class="form-wrap">

          <h3>Личные данные заявителя</h3>

          <v-text-field dense outlined label="Фамилия*" v-model="application.lastName"></v-text-field>
          <v-text-field dense outlined label="Имя*" v-model="application.firstName"></v-text-field>
          <v-text-field dense outlined label="Отчество*" v-model="application.middleName"></v-text-field>

          <h3>Контактные данные</h3>

          <v-text-field dense outlined label="Телефон" v-model="application.phone"></v-text-field>
          <v-text-field dense outlined label="Электронная почта*" v-model="application.email"></v-text-field>
          <v-text-field dense outlined label="Номер заявления из ИС" v-model="application.aisNumber"></v-text-field>

          <h3>Способ обращения гражданина за сервисом*</h3>

          <v-radio-group row v-model="application.applicationMethod">
            <v-radio label="По заявлению"></v-radio>
            <v-radio label="Предложение сотрудника ЦЗН"></v-radio>
          </v-radio-group>


          <div class="date-control">
            <v-menu
                v-model="contractDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    outlined
                    readonly
                    :value="application.date | dateFormat"
                    v-on="on"
                    label="Дата получения сервиса*"
                    append-icon="mdi-calendar-month-outline"
                ></v-text-field>
              </template>
              <v-date-picker
                  locale="ru-RU"
                  v-model="application.date"
                  @input="contractDateMenu = false"
                  first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </div>

          <div data-v-28bdef56=""
               class="time-control v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
            <div class="v-input__control">
              <div class="v-input__slot">
                <div class="v-text-field__slot">
                  <label for="input-50"
                         class="v-label v-label--active theme--light"
                         style="left: 0px; right: auto; position: absolute;">
                    Время получения сервиса*</label>
                  <div id="timepicker">
                    <vue-timepicker
                        v-model="yourTimeValue"
                        id="input-50"
                        format="HH:mm"
                        hide-clear-button
                        auto-scroll
                        advanced-keyboard
                        :manual-input-timeout="1500"
                        container-id="auto-dropdown-containter"
                    ></vue-timepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3>Образование</h3>

          <v-select dense outlined label="Уровень образования*"
                    :items="Object.values(EducationLevels)"
                    v-model="application.educationLevel"
          ></v-select>
          <v-text-field dense outlined label="Квалификация по диплому (при наличии)"
                        v-model="application.educationInfo.qualification"></v-text-field>
          <v-text-field dense outlined label="Специальность по диплому (при наличии)"
                        v-model="application.educationInfo.diplomaSpeciality"></v-text-field>

          <v-btn
              color="blue"
              style="width: 360px"
              @click="saveApplication"
          >НАЗНАЧИТЬ
          </v-btn>

        </div>


      </div>
    </transition>
  </div>
</template>

<script>

import api from "@/modules/api"
import VueTimepicker from 'vue2-timepicker'
import { EducationLevels, reverseDict } from "../modules/NSI"

export default {
  name: 'Directories',
  components: {VueTimepicker},
  data() {
    return {
      application: {
        lastName: '',
        firstName: '',
        middleName: '',
        phone: '',
        email: '',
        aisNumber: '',
        applicationMethod: '',
        localDateTime: '',
        date: '',
        time: '',
        educationInfo: {
          educationLevel: '',
          qualification: '',
          diplomaSpeciality: '',
        }
      },
      educationLevel: '',
      date: '',
      time: '',
      yourTimeValue: {
        HH: '10',
        mm: '05',
      },
      contractDateMenu: false,
      ApplicationMethod: {
        APP: 'По заявлению',
        SUGG: 'Предложение сотрудника ЦЗН'
      },
      ApplicationMethodCode: {
        0: 'APP',
        1: 'SUGG'
      },
      EducationLevels: EducationLevels,
      EducationLevelCode: reverseDict(EducationLevels),
    }
  },
  methods: {
    async saveApplication() {
      if(!this.validate()) {
        alert('Заполните все поля')
        return
      }
      let application = this.application
      if (application.applicationMethod) {
        application.applicationMethod = this.ApplicationMethodCode[application.applicationMethod]
      }

      application.localDateTime = new Date(application.date +
          'T' + this.yourTimeValue.HH + ':' + this.yourTimeValue.mm)
      application.localDateTime.setTime(application.localDateTime.getTime() - application.localDateTime.getTimezoneOffset() * 60 * 1000)

      application.educationInfo.educationLevel = this.EducationLevelCode[application.educationLevel]

      console.log(application)

      const req = await api.postJson("/applications/", application)
      console.log('applications')
      if (req.ok) {
        console.log(req)
        this.$router.push('/')
        return req.payload
      }
      alert('Ошибка при обращении к серверу!')
    },
    isNotEmpty(field){
      if(this.application[field] != null && this.application[field].toString().length > 0)
        return true
      return false
    },
    areNotEmpty(fields){
      for(let field of fields)
        if(!this.isNotEmpty(field)){
          return false}
      return true
    },
    validate() {
      return this.areNotEmpty([
          'applicationMethod',
        'date',
        'educationLevel',
        'email',
        'firstName',
        'lastName'])
    }
  },
  filters: {
    dateFormat: function (date) {
      if (!date) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  async beforeMount() {
    console.log(this.EducationLevelCode)
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
@import '~vue2-timepicker/dist/VueTimepicker.css';
</style>


<style scoped lang="scss">
</style>
